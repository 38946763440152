const getWebappUrl = () => {
    const host = location.hostname;
    if (host.includes("joinflow.com")) {
        return `https://app.joinflow.com/embed/integration?config=${encodeURIComponent("Microsoft Dynamics 365")}`;
    } else if (host.includes("telavox.com")) {
        return `https://flowapp.telavox.com/embed/integration?config=${encodeURIComponent("Microsoft Dynamics 365")}`;
    } else {
        return `${process.env.TARGET_ORIGIN}?config=${encodeURIComponent("Microsoft Dynamics 365")}`;
    }
};

const AppConfig = {
    targetOrigin: getWebappUrl(),
};

export default AppConfig;
