export const tvx = {
    AUTH_REQUIRED: "AUTH_REQUIRED",
    CALL_ANSWERED: "CALL_ANSWERED",
    CALL_ENDED: "CALL_ENDED",
    CALL_LOG: "CALL_LOG",
    CONTACT_DECORATIONS_RESPONSE: "CONTACT_DECORATIONS_RESPONSE",
    DIAL_NUMBER: "DIAL_NUMBER",
    FEATURE_FLAGS: "FEATURE_FLAGS",
    GET_CONTACT_DECORATIONS: "GET_CONTACT_DECORATIONS",
    INCOMING_CALL: "INCOMING_CALL",
    OUTGOING_CALL_STARTED: "OUTGOING_CALL_STARTED",
    PARENT_ROUTE_REQUEST: "PARENT_ROUTE_REQUEST",
    REGISTER_ORIGIN: "REGISTER_ORIGIN",
    USER_LOGGED_IN: "USER_LOGGED_IN",
    USER_LOGGED_OUT: "USER_LOGGED_OUT",
};

export const dyn = {
    ON_CLICK_TO_ACT: "onclicktoact",
    ON_MODE_CHANGED: "onmodechanged",
    ON_PAGE_NAVIGATE: "onpagenavigate",
    ON_SEND_KB_ARTICLE: "onsendkbarticle",
    ON_SIZE_CHANGED: "onsizechanged",
};
