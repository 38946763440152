import { getContactDecorations, openCreateEntityForm } from "./messageHandlers";

export const handleOpenTicketOnAnswer = async (airbrake, lib, callInfo) => {
    let metadata;
    try {
        const contacts = await getContactDecorations(lib, { numbers: [callInfo.number]});
        const contact = contacts[callInfo.number][0];
        metadata = contact.metadata;
    } catch (error) {
        console.warn("Could not get contact decoration for new ticket: ", error.message);
        airbrake.notify({ error });
    }

    const data = { callInfo, routeInfo: "ticket:new", metadata };

    await openCreateEntityForm(lib, data);
};
