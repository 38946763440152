import { CALL_DIRECTION } from "./constants/constants";

export const searchForEntityWithId = (entityTypeId, entityId) => {
    return `$filter=${entityTypeId} eq '${entityId}'`;
};

export const expandIncidents = (entityName) => (expand) => {
    return `${expand},incident_customer_${entityName}s($select=incidentid,title,ticketnumber,modifiedon,statuscode,statecode)`;
};

export const expandContactTasks = () => {
    return "$expand=Contact_Tasks($select=subject,activityid,modifiedon,statuscode,statecode;$orderby=scheduledstart asc)";
};

export const expandAccountTasks = () => {
    return "$expand=Account_Tasks($select=subject,activityid,modifiedon,statuscode,statecode;$orderby=scheduledstart asc)";
};

export const expandLeadTasks = () => {
    return "$expand=Lead_Tasks($select=subject,activityid,modifiedon,statuscode,statecode;$orderby=scheduledstart asc)";
};

const insertWildcardSymbol = (s) => {
    // Found on stackoverflow. '%' is a wildcard symbol in SQL that will match on 0 or more characters.
    // https://stackoverflow.com/a/51049659/8236993
    return s && String(s).split("").join("%");
};

export const searchForContactByNumber = (number) => {
    const wildcardNumber = insertWildcardSymbol(number);
    const encodedNumber = encodeURIComponent(wildcardNumber);

    const select =
        "$select=fullname,mobilephone,telephone1,contactid,_parentcustomerid_value";
    const filter = `$filter=contains(mobilephone,'${encodedNumber}') or contains(telephone1,'${encodedNumber}')`;

    return `${select}&${filter}`;
};

export const searchForAccountByNumber = (number) => {
    const wildcardNumber = insertWildcardSymbol(number);
    const encodedNumber = encodeURIComponent(wildcardNumber);

    const select = "$select=name,telephone1,accountid";
    const filter = `$filter=contains(telephone1,'${encodedNumber}')`;

    return `${select}&${filter}`;
};

export const searchForLeadByNumber = (number) => {
    const wildcardNumber = insertWildcardSymbol(number);
    const encodedNumber = encodeURIComponent(wildcardNumber);

    const select = "$select=fullname,telephone1,mobilephone,leadid";
    const filter = `$filter=contains(mobilephone,'${encodedNumber}') or contains(telephone1,'${encodedNumber}')`;

    return `${select}&${filter}`;
};

export const formatQueryParameters = (searchStrings) => {

    const entityQueryParameters = {};
    for (const entity in searchStrings) {
        const queryParameters = `?${searchStrings[entity].select}&${searchStrings[entity].expand}`;
        entityQueryParameters[entity] = queryParameters;
    }
    return entityQueryParameters;
};

export const microsoftIn = (property, numbers) => {
    const encodedNumbers = numbers.map(encodeURIComponent);
    return `Microsoft.Dynamics.CRM.In(PropertyName='${property}',PropertyValues=${JSON.stringify(encodedNumbers)})`;
};


export const createCallLogPayload = (direction, description, subject, currentUserId, metadataEntityObject, actualStart,  actualEnd) => {

    if (metadataEntityObject) {
        const { objectTypeId, objectType, customerName, phone } = metadataEntityObject;

        const partySystemUser = `/systemusers(${currentUserId.toLowerCase()})`;
        const partySystemUserObject = {
            "partyid_systemuser@odata.bind": partySystemUser,
            participationtypemask: 1,
        };

        const date = new Date();
        const partyDataBindValue = `/${objectType}s(${objectTypeId})`;
        const partyObject = {
            [`partyid_${objectType}@odata.bind`]: partyDataBindValue,
            participationtypemask: 2,
        };
        const regardingObjectid = {
            [`regardingobjectid_${objectType}_phonecall@odata.bind`]: partyDataBindValue
        };
        const regardingObjectName = {
            [`regardingobjectid_${objectType}_phonecall@OData.Community.Display.V1.FormattedValue`]: customerName,
        };

        // the docs says if it's an outgoing call, the directionCode value will be 1 and 0 for incoming
        // but crashes with ints, error msg says to use booleans instead
        // https://docs.microsoft.com/en-us/dynamics365/customerengagement/on-premises/developer/entities/phonecall?view=op-9-1#BKMK_DirectionCode
        const directionCode = direction.toLowerCase() === CALL_DIRECTION.OUTGOING.toLowerCase();

        return {
            scheduledend: date.toISOString(),
            description,
            subject,
            "prioritycode": 1, //Normal
            "ownerid_phonecall@odata.bind": partySystemUser,
            "ownerid_phonecall@OData.Community.Display.V1.FormattedValue": customerName,
            "phonecall_activity_parties": [
                partySystemUserObject,
                partyObject,
            ],
            ...regardingObjectid,
            ...regardingObjectName,
            ...(actualEnd && { actualend: new Date(actualEnd) }),
            ...(actualStart && { actualstart: new Date(actualStart) }),
            "directioncode": directionCode,
            "phonenumber": phone,
        };
    }

    return null;
};

export const getPhoneCallActivityFormParameters = (number, direction, metadataEntityObject) => {
    const isOutgoing = direction.toLowerCase() === CALL_DIRECTION.OUTGOING;
    let formParameters = {
        phonenumber: number,
        directioncode: isOutgoing,
        subject: undefined,
    };

    if (metadataEntityObject && metadataEntityObject.objectTypeId) {
        const { objectTypeId, objectType, customerName } = metadataEntityObject;
        // Type: Lookup
        const lookup = {
            id: objectTypeId, // Linked entity's id.
            name: customerName, // Display name of the linked entity. Can technically be any string.
            entityType: objectType, // Entity type of the linked entity.
        };

        formParameters = {
            ...formParameters,
            regardingobjectid: lookup,
            [isOutgoing ? "to" : "from"]: [lookup],
        };
    }
    return formParameters;
};

export const getIncidentFormParameters = (metadataEntityObject) => {
    if (metadataEntityObject && metadataEntityObject.objectTypeId) {
        const { objectTypeId, objectType, customerName } = metadataEntityObject;
        return {
            customerid: objectTypeId,
            customeridname: customerName, //Display name of the linked entity. Can technically be any string. For some reason it's called name for accounts
            customeridtype: objectType,
        };
    }
    return {};
};

