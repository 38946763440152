export const microsoftAPIResultToArray = (result) => {
    return Array.from({ ...result, length: Object.keys(result).length });
};

export const getAppName = () => {
    const hostName = window.location.hostname;
    return hostName === "app.joinflow.com" ? "Flow" : "Telavox";
};

export const getCallLogFormParameters = (call) => {
    const { direction, recordingUrl, durationSeconds, recordingPageUrl } = call;
    const appName = getAppName();
    const descriptionArray = [];
    descriptionArray.push(`${direction} call via ${appName}.\n\n`);
    if (recordingUrl) {
        descriptionArray.push(`If you are unable to listen to the recording
    * Wait for the recording to finish loading.
    * Make sure you have logged in to the ${appName} calling extension.
    * Make sure you have the correct user rights in ${appName} to listen to the recording.\n\n`);
    }
    descriptionArray.push(`Call recording duration ${durationSeconds} seconds.\n`);
    if (recordingPageUrl || recordingUrl) {
        descriptionArray.push(`Call record details can be found here:\n${recordingPageUrl || recordingUrl}`);
    }

    const description = descriptionArray.join("");
    return ({
        direction,
        description,
        subject: "Call log",
        actualStart: call.answered,
        actualEnd: call.disconnected,
    });
};
