import { ENTITY_NAME, ROUTE_INFO_ID_TYPE, BUTTON_LABEL, STATE_CODE, STATUS_CODE } from "./constants/constants";
import {
    getEntityHits,
    getCallDecorationsForCall,
    getCallDecorationsForHistory,
    getEntityObjectFromMetadata,
} from "./messageHandlerUtils";
import {
    formatQueryParameters,
    searchForEntityWithId,
    createCallLogPayload,
    getPhoneCallActivityFormParameters,
    getIncidentFormParameters
} from "./microsoftAPIStringUtils";
import {
    entityNameToEntityId,
    getQueryParametersForEntitiesByNumber,
    getQueryParameters,
    searchForContacts,
    searchForAccounts,
    searchForLeads,
} from "./microsoftAPIUtils";
import {
    getExistingEntities,
    getCurrentUser,
} from "./tempdata";
import { getCallLogFormParameters } from "./utils";

export const getContactDecorations = async (lib, data) => {
    const { numbers } = data;
    const [account, contact, lead, phonecall, , incident] = await getExistingEntities();

    const contactRouteButtons = [];
    phonecall && contactRouteButtons.push({
        label: BUTTON_LABEL.CREATE_ACTIVITY,
        link: `${ENTITY_NAME.PHONE_CALL}:${ROUTE_INFO_ID_TYPE.NEW}`,
        altLink: `${ENTITY_NAME.PHONE_CALL}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
    });

    incident && contactRouteButtons.push({
        label: BUTTON_LABEL.CREATE_CASE,
        link: `${ENTITY_NAME.INCIDENT}:${ROUTE_INFO_ID_TYPE.NEW}`,
        altLink: `${ENTITY_NAME.INCIDENT}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
    });

    const defaultData = {
        contactRouteButtons,
    };

    let decorateEntity;
    let entitiesList = [];

    if (numbers.length === 1) {
        const numberE164Flow = numbers[0];
        defaultData.numberE164Flow = numberE164Flow;

        const entityList = [ENTITY_NAME.CONTACT, ENTITY_NAME.ACCOUNT, ENTITY_NAME.LEAD];
        const searchStrings = await getQueryParametersForEntitiesByNumber(entityList, numberE164Flow);
        const entitiesQueryParameters = formatQueryParameters(searchStrings);
        const searchOnly = true;
        decorateEntity = getCallDecorationsForCall;
        entitiesList.push(await getEntityHits(lib)(entitiesQueryParameters, searchOnly));
    } else {
        decorateEntity = getCallDecorationsForHistory;

        const promises = [];
        contact && promises.push(searchForContacts(lib)(numbers));
        account && promises.push(searchForAccounts(lib)(numbers));
        lead && promises.push(searchForLeads(lib)(numbers));
        entitiesList = await Promise.all(promises);
    }
    const decoratedEntities = {};

    const removeAllButDigits = (str) => {
        return str.replace(/\D/g, "");
    };
    numbers.forEach(number => {
        decoratedEntities[number] = [];
        entitiesList.forEach(entity => {
            entity.forEach(hit => {
                const compNumber = removeAllButDigits(number);
                const mp = hit.mobilephone && removeAllButDigits(hit.mobilephone);
                const tp = hit.telephone1 && removeAllButDigits(hit.telephone1);
                if (compNumber === mp || compNumber === tp) {
                    if (hit.contactid) {
                        const contact = decorateEntity(ENTITY_NAME.CONTACT, hit);
                        const decoratedContact = { ...contact, ...defaultData, metadata: hit };
                        decoratedEntities[number].push(decoratedContact);
                    } else if (hit.accountid) {
                        const account = decorateEntity(ENTITY_NAME.ACCOUNT, hit);
                        const decoratedAccount = { ...account, ...defaultData, metadata: hit };
                        decoratedEntities[number].push(decoratedAccount);
                    } else if (hit.leadid) {
                        const lead = decorateEntity(ENTITY_NAME.LEAD, hit);
                        const decoratedLead = { ...lead, ...defaultData, metadata: hit };
                        decoratedEntities[number].push(decoratedLead);
                    }
                }
            });
        });
    });

    return decoratedEntities;
};

export const openRecord = async (lib, data) => {
    const { routeInfo } = data;

    const splitRouteInfo = routeInfo.split(":");
    const entityName = splitRouteInfo[0];
    const entityId = splitRouteInfo[1];
    const newTab = splitRouteInfo[2];
    const idName = entityNameToEntityId(entityName);
    const search = searchForEntityWithId(idName, entityId);
    const searchOnly = false; // false means it opens the record page if it got one search hit, the search page on multiple hits.

    if (newTab) {
        const appUrl = getCurrentUser().appUrl;
        const customerUrl = `${appUrl}&forceUCI=1&pagetype=entityrecord&etn=${entityName}&id=${entityId}`;
        window.open(customerUrl);
        return { message: "open new tab", url: customerUrl };
    }
    return lib
        .searchAndOpenRecords(entityName, search, searchOnly)
        .then(JSON.parse);
};

export const createNewTab = (data) => {
    const { routeInfo, metadata } = data;
    const [ type ] = routeInfo.split(":"); //avalible type => ENTITY_NAME
    const metadataEntityObject = getEntityObjectFromMetadata(metadata);
    const extraqsParam = getQueryParameters(type, metadataEntityObject);
    const appUrl = getCurrentUser().appUrl;
    //forceUCI=1 means that this UI is Unified Interface app.
    window.open(`${appUrl}&forceUCI=1&pagetype=entityrecord&etn=${type}${extraqsParam}`);
};

export const createCallRecord = async (lib, call, metadataEntityObject) => {
    const { direction, description, subject, actualStart,  actualEnd } = getCallLogFormParameters(call);
    const currentUser = getCurrentUser();
    const callLogData = createCallLogPayload(direction, description, subject, currentUser.contactid, metadataEntityObject, actualStart, actualEnd);
    if (callLogData) {
        lib.createRecord(ENTITY_NAME.PHONE_CALL, JSON.stringify(callLogData)).then(record => {
            const data = {
                "statecode": STATE_CODE.COMPLETED,
                "statuscode": STATUS_CODE.MADE,
            };

            record = JSON.parse(record);
            return lib.updateRecord(ENTITY_NAME.PHONE_CALL, record.id, JSON.stringify(data));
        }).then(JSON.parse);
    }
    return null;
};

export const openCreateEntityForm = async (lib, data) => {
    const { callInfo, routeInfo, metadata } = data;
    let [ type ] = routeInfo.split(":");
    if (type == "ticket") type = "incident";

    const entityFormOptions = {
        entityName: type,
    };

    const metadataEntityObject = getEntityObjectFromMetadata(metadata);

    let formParameters = {};
    if (type === ENTITY_NAME.PHONE_CALL) {
        if (callInfo) {
            const { number, direction } = callInfo;
            formParameters = getPhoneCallActivityFormParameters(number, direction, metadataEntityObject);
        }
    } else if (type === ENTITY_NAME.INCIDENT) {
        formParameters = getIncidentFormParameters(metadataEntityObject);
    } else {
        formParameters = {
            telephone1: callInfo.number,
        };
    }

    return lib.openForm(JSON.stringify(entityFormOptions), JSON.stringify(formParameters))
        .then(JSON.parse);
};
