import { initAirBrake } from "@tvx/airbrake";
import { stringifyFilter } from "./airbrakeUtils";

const airbrake = initAirBrake(
    process.env.NODE_ENV === "test" ? "test" : process.env.AIRBRAKE_PROJECT_ID,
    process.env.NODE_ENV === "test" ? "test" : process.env.AIRBRAKE_PROJECT_KEY
);

airbrake.addFilter(stringifyFilter);

export const getAirbrakeInstance = () => airbrake;
