import { ENTITY_NAME, ENTITY_TYPE_LABEL, STATE_CODE } from "./constants/constants";
import {
    handleMicrosoftAPIError,
    handleNoRecords,
} from "./microsoftAPIUtils";
import { getCurrentUser } from "./tempdata";

const getEntityUrl = (entityName, entityId) => {
    const { appUrl } = getCurrentUser();
    const entity = `etn=${entityName}`;
    const id = `id=${entityId}`;
    const pageType = "pagetype=entityrecord";
    return `${appUrl}&${pageType}&${entity}&${id}`;
};

export const toStatus = (stateCode) => {
    return stateCode === STATE_CODE.OPEN ? "OPEN" : null;
};

export const taskMapper = (task) => {
    return {
        type: "Task",
        routeLink: `task:${task.activityid}`,
        routeName: task.subject,
        routeNumber: task.activityid.slice(0, 4),
        routeUrl: getEntityUrl(ENTITY_NAME.TASK, task.activityid),
        ...(toStatus(task.statecode) && { status: toStatus(task.statecode) }),
        ...(task.modifiedon && { timestamp: Date.parse(task.modifiedon) }),
    };
};

export const extractIncidentRoutes = (entityName) => (entity) => {
    const incidents = entity[`incident_customer_${entityName}s`];

    let otherRoutes = [];
    if (incidents && incidents.length > 0) {
        otherRoutes = incidents.map((incident) => ({
            type: "Case",
            routeLink: `incident:${incident.incidentid}`,
            routeName: incident.title,
            routeNumber: incident.ticketnumber,
            routeUrl: getEntityUrl(ENTITY_NAME.INCIDENT, incident.incidentid),
            ...(toStatus(incident.statecode) && { status: toStatus(incident.statecode) }),
            ...(incident.modifiedon && { timestamp: Date.parse(incident.modifiedon) }),
        }));
    }

    return otherRoutes;
};

export const extractOtherRoutes = (entityName) => (entity) => {
    const entityTasksKey = `${entityName.charAt(0).toUpperCase() + entityName.slice(1)}_Tasks`;
    const entityTasks = entity[entityTasksKey];
    let otherRoutes = [];
    if (entityTasks.length > 0) {
        otherRoutes = entityTasks.map(taskMapper);
    }

    return otherRoutes;
};

export const microsoftAPIResultToArray = (result) => {
    return Array.from({ ...result, length: Object.keys(result).length });
};

export const getCallDecorationsForCall = (entity, entityHit) => {
    switch (entity) {
        case ENTITY_NAME.CONTACT:
            return getCallDecorationForContact(entityHit);
        case ENTITY_NAME.ACCOUNT:
            return getCallDecorationForAccount(entityHit);
        case ENTITY_NAME.LEAD:
            return getCallDecorationForLead(entityHit);
        default:
            return null;
    }
};

export const getCallDecorationForContact = (contact) => {

    const parentRoutes = [];
    if (contact._parentcustomerid_value) {
        const parentAccountId = contact._parentcustomerid_value;
        const parentAccountName = contact["_parentcustomerid_value@OData.Community.Display.V1.FormattedValue"];
        const parentEntityType = contact["_parentcustomerid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];

        parentRoutes.push({
            routeLink: `${parentEntityType}:${parentAccountId}`,
            routeName: parentAccountName,
            type: parentEntityType,
            routeUrl: getEntityUrl(parentEntityType, parentAccountId),
        });
    }

    const otherRoutes = [];
    otherRoutes.push(...extractIncidentRoutes(ENTITY_NAME.CONTACT)(contact));
    otherRoutes.push(...extractOtherRoutes(ENTITY_NAME.CONTACT)(contact));

    const limitedOtherRoutes = [
        ...parentRoutes,
        ...otherRoutes,
    ];

    return {
        typeLabel: ENTITY_TYPE_LABEL.CONTACT,
        nameFlow: contact.fullname,
        routeFlow: `${ENTITY_NAME.CONTACT}:${contact.contactid}`,
        altRouteFlow: `${ENTITY_NAME.CONTACT}:${contact.contactid}:newTab`,
        otherRoutesFlow: limitedOtherRoutes,
        routeUrl: getEntityUrl(ENTITY_NAME.CONTACT, contact.contactid),
    };
};

export const getCallDecorationForAccount = (account) => {

    const otherRoutes = [];
    otherRoutes.push(...extractIncidentRoutes(ENTITY_NAME.ACCOUNT)(account));
    otherRoutes.push(...extractOtherRoutes(ENTITY_NAME.ACCOUNT)(account));

    return {
        typeLabel: ENTITY_TYPE_LABEL.ACCOUNT,
        nameFlow: account.name,
        routeFlow: `${ENTITY_NAME.ACCOUNT}:${account.accountid}`,
        altRouteFlow: `${ENTITY_NAME.ACCOUNT}:${account.accountid}:newTab`,
        otherRoutesFlow: otherRoutes,
        routeUrl: getEntityUrl(ENTITY_NAME.ACCOUNT, account.accountid),
    };
};

export const getCallDecorationForLead = (lead) => {

    const otherRoutes = extractOtherRoutes(ENTITY_NAME.LEAD)(lead);

    return {
        typeLabel: ENTITY_TYPE_LABEL.LEAD,
        nameFlow: lead.fullname,
        routeFlow: `${ENTITY_NAME.LEAD}:${lead.leadid}`,
        altRouteFlow: `${ENTITY_NAME.LEAD}:${lead.leadid}:newTab`,
        otherRoutesFlow: otherRoutes,
        routeUrl: getEntityUrl(ENTITY_NAME.LEAD, lead.leadid),
    };
};

export const getCallDecorationsForHistory = (entity, entityHit) => {
    switch (entity) {
        case ENTITY_NAME.CONTACT:
            return getCallsDecorationForContact(entityHit);
        case ENTITY_NAME.ACCOUNT:
            return getCallsDecorationForAccount(entityHit);
        case ENTITY_NAME.LEAD:
            return getCallsDecorationForLead(entityHit);
        default:
            return null;
    }
};

export const getCallsDecorationForContact = (contact) => ({
    typeLabel: ENTITY_TYPE_LABEL.CONTACT,
    nameFlow: contact.fullname,
    routeFlow: `contact:${contact.contactid}`,
    altRouteFlow: `${ENTITY_NAME.CONTACT}:${contact.contactid}:newTab`,
    routeUrl: getEntityUrl(ENTITY_NAME.CONTACT, contact.contactid),
});

export const getCallsDecorationForAccount = (account) => ({
    typeLabel: ENTITY_TYPE_LABEL.ACCOUNT,
    nameFlow: account.name,
    routeFlow: `account:${account.accountid}`,
    altRouteFlow: `${ENTITY_NAME.ACCOUNT}:${account.accountid}:newTab`,
    routeUrl: getEntityUrl(ENTITY_NAME.ACCOUNT, account.accountid),
});

export const getCallsDecorationForLead = (lead) => ({
    typeLabel: ENTITY_TYPE_LABEL.LEAD,
    nameFlow: lead.fullname,
    routeFlow: `${ENTITY_NAME.LEAD}:${lead.leadid}`,
    altRouteFlow: `${ENTITY_NAME.LEAD}:${lead.leadid}:newTab`,
    routeUrl: getEntityUrl(ENTITY_NAME.LEAD, lead.leadid),
});

export const getEntityHits = (lib) => async (queryParameters, searchOnly) => {
    const entityHits = [];
    for (const queryEntity in queryParameters) {
        await lib
            .searchAndOpenRecords(queryEntity, queryParameters[queryEntity], searchOnly)
            .catch(handleNoRecords(queryEntity))
            .catch(handleMicrosoftAPIError)
            .then(JSON.parse)
            .then((result) => {
                Object.values(result)
                    .forEach(hit => {
                        entityHits.push(hit);
                    });
            })
            .catch((error) => {
                console.warn(`Could not get ${queryEntity} hit`, JSON.stringify(error));
                return {};
            });
    }
    return entityHits;
};


export const getObjectTypeFromMetadata = (metadata) => (metadata.contactid && ENTITY_NAME.CONTACT) || (metadata.accountid && ENTITY_NAME.ACCOUNT) || (metadata.leadid && ENTITY_NAME.LEAD);

export const getObjectTypeIdFromMetadata = (metadata) => metadata.contactid || metadata.accountid || metadata.leadid;

export const getCustomerNameFromMetadata = (metadata) => metadata.fullname || metadata.name;

export const getObjectPhoneFromMetadata = (metadata) => metadata.telephone1 || metadata.mobilephone;

/**
 * function to get the correct entity type from metadata,
 * It can only be one type
 */
export const getEntityObjectFromMetadata = (metadata) => {
    if (metadata && (Object.keys(metadata).length > 0)) {
        const objectTypeId = getObjectTypeIdFromMetadata(metadata);
        const objectType = getObjectTypeFromMetadata(metadata);
        const customerName = getCustomerNameFromMetadata(metadata);
        const phone = getObjectPhoneFromMetadata(metadata);
        return {
            objectTypeId,
            objectType,
            customerName,
            phone,
        };
    }
    return null;
};
