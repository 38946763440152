import { ENTITY_NAME } from "./constants/constants";

let currentCallData = {};

export const setCurrentCallData = (data) => {
    currentCallData = JSON.parse(JSON.stringify(data));
    if (data.contactid) {
        currentCallData.type = ENTITY_NAME.CONTACT;
        currentCallData.id = data.contactid;
        delete currentCallData.contactid;
    } else if (data.accountid) {
        currentCallData.type = ENTITY_NAME.ACCOUNT;
        currentCallData.fullname = data.name;
        currentCallData.id = data.accountid;
        delete currentCallData.accountid;
        delete currentCallData.name;
    } else if (data.leadid) {
        currentCallData.type = ENTITY_NAME.LEAD;
        currentCallData.id = data.leadid;
        delete currentCallData.leadid;
    }
};

export const getCurrentCallData = () => {
    return currentCallData;
};

export const resetCurrentCallData = () => {
    currentCallData = {};
};

let currentUser = {};

export const setCurrentUser = (data) => {
    currentUser = {
        ...data,
        contactid: data.userId && data.userId.replace("{", "").replace("}", ""),
    };
};

export const getCurrentUser = () => {
    return currentUser;
};

export const resetCurrentUser = () => {
    currentUser = {};
};

let existingEntities;

/**
 * Set a Promise with an array of entities.
 *
 * @param {Promise} entitiesPromises [account, contact, lead, phonecall, task, incident]
 */
export const setExistingEntities = (entitiesPromises) => {
    existingEntities = entitiesPromises;
};

/**
 * Gets the Promise with an array entities.
 *
 * @returns [account, contact, lead, phonecall, task, incident]
 */
export const getExistingEntities = () => {
    return existingEntities;
};
