export const FIELD_TYPE = {
    PHONE: "Phone",
};

export const CALL_PARTICIPANT = {
    FROM: 1,
    TO: 2,
};

export const CALL_DIRECTION = {
    INCOMING: "incoming",
    OUTGOING: "outgoing",
};

export const ENTITY_NAME = {
    CONTACT: "contact",
    ACCOUNT: "account",
    PHONE_CALL: "phonecall",
    TASK: "task",
    LEAD: "lead",
    INCIDENT: "incident",
};

export const ENTITY_PROPERTY = {
    MOBILEPHONE: "mobilephone",
    BUSINESS_PHONE: "telephone1"
};

export const CTI_MODE = {
    MINIMIZED: 0,
    DOCKED: 1,
    HIDDEN: 2, // CIF v2.0
};

export const TVX_FORM_TYPE = {
    NOTE: "note"
};

export const ROUTE_INFO_ID_TYPE = {
    NEW_TAB: "newTab",
    NEW: "new",
    HITS: "hits",
};

export const ENTITY_TYPE_LABEL = {
    ACCOUNT: "cti.wrapper.type.label.account",
    CONTACT: "cti.wrapper.type.label.contact",
    LEAD: "cti.wrapper.type.label.lead",
};

export const BUTTON_LABEL = {
    CREATE_ACTIVITY: "cti.wrapper.button.create.activity",
    CREATE_CONTACT: "cti.wrapper.button.create.contact",
    CREATE_LEAD: "cti.wrapper.button.create.lead",
    CREATE_CASE: "cti.wrapper.button.create.case"
};

//The statusCode field represents the status of the record within a specific stage of the record's lifecycle
//https://learn.microsoft.com/en-us/dynamics365/customerengagement/on-premises/developer/entities/phonecall?view=op-9-1#BKMK_StatusCode
export const STATUS_CODE = {
    OPEN: 1,
    MADE: 2,
    CANCELED: 3,
    RECEIVED: 4
};

//The stateCode field, represents the overall state of the record. It indicates whether the record is in an active or inactive state
//https://learn.microsoft.com/en-us/dynamics365/customerengagement/on-premises/developer/entities/phonecall?view=op-9-1#statecode-choicesoptions
export const STATE_CODE = {
    OPEN: 0,
    COMPLETED: 1,
    CANCELED: 2,
};
