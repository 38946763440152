import { CTI_MODE, FIELD_TYPE } from "./constants/constants";
import { tvx } from "./constants/message-events";
import { tvxPostMessage } from "./tvx-cli";

export const getOnClickToActHandler = (lib) => (event) => {
    const data = JSON.parse(event);

    if (data.format === FIELD_TYPE.PHONE) {
        lib.setMode(CTI_MODE.DOCKED);

        tvxPostMessage({
            type: tvx.DIAL_NUMBER,
            phoneNumber: data.value,
        });
    }

    return Promise.resolve();
};
