import { dyn } from "./constants/message-events";
import { MicrosoftCIFrameworkPromise } from "./microsoft-ci-framework";
import {
    openCreateEntityForm,
    getContactDecorations,
    openRecord,
    createNewTab,
    createCallRecord,
} from "./messageHandlers";
import { handleOpenTicketOnAnswer } from "./ticketUtils";
import { CTI_MODE, ENTITY_NAME, ROUTE_INFO_ID_TYPE, BUTTON_LABEL } from "./constants/constants";
import {
    setCurrentUser,
    setExistingEntities,
} from "./tempdata";
import { getAirbrakeInstance } from "./airbrake";
import { getOnClickToActHandler } from "./handlers";
import AppConfig from "./constants/appconfig";
import SweetSuite from "@tvx/sweet-suite";
import { getTvxIframe } from "./domUtils";
import { getEntityObjectFromMetadata } from "./messageHandlerUtils";

console.info(`Microsoft Dynamics 365 CTI v${process.env.VERSION}`);

const iframe = document.getElementById("telavox-cti");
iframe.src = AppConfig.targetOrigin;

// This should be called asap in order to catch as many errors as possible
const airbrake = getAirbrakeInstance();

const addHandlers = (lib) => {
    lib.addHandler(dyn.ON_CLICK_TO_ACT, getOnClickToActHandler(lib));
};

MicrosoftCIFrameworkPromise.then((microsoftCIFramework) => {
    microsoftCIFramework.setMode(CTI_MODE.MINIMIZED);

    const doesEntityExist = (lib) => (entityName) =>
        new Promise((resolve) => {
            lib.searchAndOpenRecords(
                entityName,
                `$select=${
                    [ENTITY_NAME.TASK, ENTITY_NAME.PHONE_CALL].includes(entityName) ? "activity" : entityName
                }id&$top=1`,
                true
            )
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });

    const doesMicrosoftEntityExist = doesEntityExist(microsoftCIFramework);
    const existingEntitiesPromise = Promise.all([
        doesMicrosoftEntityExist(ENTITY_NAME.ACCOUNT),
        doesMicrosoftEntityExist(ENTITY_NAME.CONTACT),
        doesMicrosoftEntityExist(ENTITY_NAME.LEAD),
        doesMicrosoftEntityExist(ENTITY_NAME.PHONE_CALL),
        doesMicrosoftEntityExist(ENTITY_NAME.TASK),
        doesMicrosoftEntityExist(ENTITY_NAME.INCIDENT),
    ]).then((results) => {
        const sweetSuiteOptions = getHandleMessage(results);
        const sweetSuite = new SweetSuite(sweetSuiteOptions);
        sweetSuite.startListener();
        return results;
    });

    setExistingEntities(existingEntitiesPromise);

    microsoftCIFramework.setWidth(400).catch((error) => {
        console.warn("Couldn't set custom window size for CTI:", error);
        if (typeof error === "string") {
            throw new Error(error);
        } else {
            throw error;
        }
    });

    microsoftCIFramework.getEnvironment()
        .then(JSON.parse)
        .then((data) => {
            setCurrentUser(data);
        })
        .catch((error) => {
            console.warn("Couldn't get logged in user data:", error);
            if (typeof error === "string") {
                throw new Error(error);
            } else {
                throw error;
            }
        });

    addHandlers(microsoftCIFramework);

    const getHandleMessage = ([, contact, lead, phonecall, , incident,]) => {
        return {
            eventHandlers: {
                onGetContactDecorations: async (data) => {
                    let entities = {};
                    try {
                        entities = await getContactDecorations(
                            microsoftCIFramework,
                            data
                        );
                        return entities;
                    } catch (error) {
                        const numbers = data.numbers;
                        numbers.forEach(number => {
                            entities[number] = [];
                        });
                        console.warn(
                            "Failed to get contact decorations for call or calls. Message:",
                            error
                        );
                        airbrake.notify({ error });
                        return entities;
                    }
                },
                onIncomingCall: () => {
                    microsoftCIFramework.setMode(CTI_MODE.DOCKED);
                },
                onOutgoingCallStarted: () => {
                    microsoftCIFramework.setMode(CTI_MODE.DOCKED);
                },
                onParentRouteRequest: (data) => {
                    const { routeInfo } = data;
                    const [, id] = routeInfo.split(":");
                    switch (id) {
                        case ROUTE_INFO_ID_TYPE.NEW_TAB: {
                            createNewTab(data);
                            break;
                        }
                        case ROUTE_INFO_ID_TYPE.NEW: {
                            openCreateEntityForm(microsoftCIFramework, data);
                            break;
                        }
                        default: {
                            openRecord(microsoftCIFramework, data)
                                .then((result) =>
                                    console.info("Opened record:", result)
                                )
                                .catch((error) => {
                                    console.warn(
                                        "Could not open record:",
                                        error.message
                                    );
                                    airbrake.notify({ error });
                                });
                        }
                    }

                    if (data.ctiSettings) {
                        const { hideOnAction }  = data.ctiSettings;
                        microsoftCIFramework.setMode(hideOnAction.value ? CTI_MODE.MINIMIZED : CTI_MODE.DOCKED);
                    }
                },
                onUserLoggedIn: () => {
                },
                onUserLoggedOut: () => {
                },
                onCallAnswered: async (data) => {
                    const { openOnAnswer, callInfo } = data;

                    if (!callInfo) return;

                    switch (openOnAnswer) {
                        case "open_ticket":
                            await handleOpenTicketOnAnswer(airbrake, microsoftCIFramework, callInfo);
                            break;
                        case "off":
                            break;
                        default:
                            break;
                    }
                },
                onCallEnded: () => {
                },
                onCallLog: (data) => {
                    if (data.ctiSettings) {
                        const { logCallsAutomatically }  = data.ctiSettings;
                        if (logCallsAutomatically.value) {
                            const { metadata } = data;
                            const metadataEntityObject = getEntityObjectFromMetadata(metadata);
                            if (metadataEntityObject) {
                                createCallRecord(microsoftCIFramework, data.call, metadataEntityObject);
                            }
                        }
                    }
                },
                onInitiated: (data) => {
                    const defaultButtons = [];
                    lead && defaultButtons.push({
                        label: BUTTON_LABEL.CREATE_LEAD,
                        link: `${ENTITY_NAME.LEAD}:${ROUTE_INFO_ID_TYPE.NEW}`,
                        altLink: `${ENTITY_NAME.LEAD}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
                    });
                    contact && defaultButtons.push({
                        label: BUTTON_LABEL.CREATE_CONTACT,
                        link: `${ENTITY_NAME.CONTACT}:${ROUTE_INFO_ID_TYPE.NEW}`,
                        altLink: `${ENTITY_NAME.CONTACT}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
                    });
                    phonecall && defaultButtons.push({
                        label: BUTTON_LABEL.CREATE_ACTIVITY,
                        link: `${ENTITY_NAME.PHONE_CALL}:${ROUTE_INFO_ID_TYPE.NEW}`,
                        altLink: `${ENTITY_NAME.PHONE_CALL}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
                    });
                    incident && defaultButtons.push({
                        label: BUTTON_LABEL.CREATE_CASE,
                        link: `${ENTITY_NAME.INCIDENT}:${ROUTE_INFO_ID_TYPE.NEW}`,
                        altLink: `${ENTITY_NAME.INCIDENT}:${ROUTE_INFO_ID_TYPE.NEW_TAB}`,
                    });

                    if (data.ctiSettings) {
                        const { showOnLoad } = data.ctiSettings;
                        if (showOnLoad.value === "loggedIn" && data.loggedIn) {
                            microsoftCIFramework.setMode(CTI_MODE.DOCKED);
                        } else {
                            microsoftCIFramework.setMode(showOnLoad.value === "show" ? CTI_MODE.DOCKED : CTI_MODE.MINIMIZED);
                        }
                    }

                    return {
                        description: "Microsoft Dynamics 365",
                        defaultButtons,
                        ctiHostSetting: {
                            showOnLoad: "show",
                            hideOnAction: false,
                            openOnCall: "off",
                            openOnAnswer: "off",
                            logCallsAutomatically: false,
                        },
                    };
                },
            },
            delayListener: true,
            targetOrigin: AppConfig.targetOrigin,
            providedIframe: getTvxIframe().contentWindow,
            optionalAirbrake: airbrake,
        };
    };
});
