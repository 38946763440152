const scriptPromise = new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerURL = urlParams.get("base");
    const script = document.createElement("script");
    document.body.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
    script.type = "text/javascript";
    script.async = true;
    script.src = `${customerURL}/webresources/Widget/msdyn_ciLibrary.js`;
    script["data-crmurl"] = customerURL;
    script["data-cifid"] = "CIFMainLibrary";
});

export const MicrosoftCIFrameworkPromise = scriptPromise
    .then(() => new Promise((resolve, reject) => {
        (function loop(retries) {
            setTimeout(() => {
                if (
                    window.Microsoft &&
                    window.Microsoft.CIFramework &&
                    typeof window.Microsoft.CIFramework.getEnvironment === "function"
                ) {
                    resolve(window.Microsoft.CIFramework);
                } else if (retries > 100) {
                    reject("Timeout waiting for Microsoft.CIFramework to load");
                } else {
                    loop(++retries);
                }
            }, 100);
        })(0);
    }))
    .catch((error) => {
        throw Error(`Couldn't get Microsoft.CIFramework: ${error}`);
    });
