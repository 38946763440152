import {
    CALL_PARTICIPANT,
    ENTITY_NAME,
    ENTITY_PROPERTY,
} from "./constants/constants";
import { microsoftAPIResultToArray } from "./utils";
import {
    expandAccountTasks,
    expandContactTasks,
    expandIncidents,
    expandLeadTasks,
    microsoftIn,
    searchForAccountByNumber,
    searchForContactByNumber,
    searchForLeadByNumber,
} from "./microsoftAPIStringUtils";
import { getExistingEntities } from "./tempdata";

export const getQueryParametersForEntitiesByNumber = async (entities, number) => {

    const searchStrings = {};

    const [, , , , , incidentsExist] = await getExistingEntities();

    entities.forEach(entity => {
        const shouldExpand = (incidentsExist && entity !== ENTITY_NAME.LEAD);

        const select = searchForEntityByNumber(entity, number);
        const expand = shouldExpand ? expandIncidents(entity)(expandEntityTasks(entity)) : expandEntityTasks(entity);

        searchStrings[entity] = {
            select: select,
            expand: expand,
        };
    });

    return searchStrings;
};

export const searchForEntityByNumber = (entity, number) => {

    switch (entity) {
        case ENTITY_NAME.CONTACT:
            return searchForContactByNumber(number);

        case ENTITY_NAME.ACCOUNT:
            return searchForAccountByNumber(number);

        case ENTITY_NAME.LEAD:
            return searchForLeadByNumber(number);
    }
};

export const searchForNumbers = (numbers) => (entityType) => {
    const isAccountType = ENTITY_NAME.ACCOUNT === entityType;

    const getSelect = () => {
        return `$select=fullname,telephone1,mobilephone,${entityType}id`;
    };

    const getAccountSelect = () => {
        return "$select=name,telephone1,accountid";
    };

    const getFilter = () => {
        return `$filter=${microsoftIn(
            ENTITY_PROPERTY.MOBILEPHONE,
            numbers
        )} or ${microsoftIn(ENTITY_PROPERTY.BUSINESS_PHONE, numbers)}`;
    };

    const getAccountFilter = () => {
        return `$filter=${microsoftIn(
            ENTITY_PROPERTY.BUSINESS_PHONE,
            numbers
        )}`;
    };

    const select = isAccountType ? getAccountSelect() : getSelect();
    const filter = isAccountType ? getAccountFilter() : getFilter();
    return `${select}&${filter}`;
};

export const expandEntityTasks = (entity) => {
    switch (entity) {
        case ENTITY_NAME.CONTACT:
            return expandContactTasks();
        case ENTITY_NAME.ACCOUNT:
            return expandAccountTasks();
        case ENTITY_NAME.LEAD:
            return expandLeadTasks();
    }
};

export const createActivityParties = (
    isOutgoingCall,
    loggedInUserId,
    id,
    type
) => {
    const activityParties = [
        {
            participationtypemask: isOutgoingCall ? CALL_PARTICIPANT.FROM : CALL_PARTICIPANT.TO,
            "partyid_systemuser@odata.bind": `/systemusers(${loggedInUserId})`,
        },
    ];

    if (id) {
        const part = {
            participationtypemask: isOutgoingCall ? CALL_PARTICIPANT.TO : CALL_PARTICIPANT.FROM,
        };

        if (type === ENTITY_NAME.ACCOUNT || type === ENTITY_NAME.CONTACT) {
            part[`partyid_${type}@odata.bind`] = `/${type}s(${id})`;
        } else {
            part["partyid_contact@odata.bind"] = `/contacts(${id})`;
        }

        activityParties.push(part);
    }

    return activityParties;
};

export const handleMicrosoftAPIError = (error) => {
    throw Error(JSON.parse(error).value.errorMsg);
};

export const entityNameToEntityId = (entityName) => {
    switch (entityName) {
        case ENTITY_NAME.TASK:
        case ENTITY_NAME.PHONE_CALL:
            return "activityid";
        default:
            return `${entityName}id`;
    }
};

export const handleNoRecords = (entityName) => (error) => {
    const parsedError = JSON.parse(error);
    const n = parsedError.n === "searchandopenrecords";
    const message = parsedError.message === `The entity "${entityName}" cannot be found. Specify a valid query, and try again.`;
    if (n && message) {
        // Just pretend we didn't find a hit on the entity, because the entity doesn't exist in the current app.
        return JSON.stringify([]);
    } else {
        // Rethrow because this was a normal Microsoft API error.
        throw error;
    }
};

/**
 * Docs https://docs.microsoft.com/en-us/previous-versions/dynamicscrm-2016/developers-guide/gg334375(v=crm.8)?redirectedfrom=MSDN
 */
export const getQueryParameters = (type, metadata) => {
    const extraqs = "extraqs";
    let extraqsQuery;
    switch (type) {
        case ENTITY_NAME.INCIDENT: {
            const fullname = metadata.customerName;
            const customerId = metadata.objectTypeId;
            extraqsQuery = `customeridname=${fullname}&customerid=${customerId}&customeridtype${type}`;
            break;
        }
        case ENTITY_NAME.PHONE_CALL: {
            //not able to set "call to" via query parameter
            extraqsQuery = `phonenumber=${metadata.phone}`;
            break;
        }
        case ENTITY_NAME.LEAD:
        case ENTITY_NAME.CONTACT: {
            extraqsQuery = `telephone1=${metadata.phone}`;
            break;
        }
        default: {
            return;
        }
    }
    return `&${extraqs}=${encodeURIComponent(extraqsQuery)}`;
};

const handleSearchFailure = (args) => (error) => {
    const message = error.message === "Invalid Argument";
    const n = error.n === "searchandopenrecords";
    if (message && n) {
        throw new Error (
            `Invalid arguments: ${args}`,
            {
                cause: error
            }
        );
    }
    throw error;
};

export const searchForAccounts = (lib) => async (numbers) => {
    const entityLogicalName = ENTITY_NAME.ACCOUNT;
    const queryParameters = searchForNumbers(Array.from(numbers))(
        ENTITY_NAME.ACCOUNT
    );

    return await lib
        .searchAndOpenRecords(
            entityLogicalName,
            queryParameters,
            true
        )
        .catch(handleSearchFailure([entityLogicalName, queryParameters]))
        .then(JSON.parse)
        .then(microsoftAPIResultToArray)
        .then(extractSearchResultValues);
};

export const searchForContacts = (lib) => async (numbers) => {
    const entityLogicalName = ENTITY_NAME.CONTACT;
    const queryParameters = searchForNumbers(Array.from(numbers))(
        ENTITY_NAME.CONTACT
    );

    return await lib
        .searchAndOpenRecords(
            entityLogicalName,
            queryParameters,
            true
        )
        .catch(handleSearchFailure([entityLogicalName, queryParameters]))
        .then(JSON.parse)
        .then(microsoftAPIResultToArray)
        .then(extractSearchResultValues);
};

export const searchForLeads = (lib) => async (numbers) => {
    const entityLogicalName = ENTITY_NAME.LEAD;
    const queryParameters = searchForNumbers(Array.from(numbers))(
        ENTITY_NAME.LEAD
    );

    return await lib
        .searchAndOpenRecords(
            entityLogicalName,
            queryParameters,
            true
        )
        .catch(handleSearchFailure([entityLogicalName, queryParameters]))
        .then(JSON.parse)
        .then(microsoftAPIResultToArray)
        .then(extractSearchResultValues);
};

const extractSearchResultValues = (searchResult) => searchResult && Object.values(searchResult);
